.App {
  text-align: left;

}



.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*_____________________________________________________________*/


p {
  margin: 0;
  padding: 0;
}

/* Estilo do corpo da página */
body {
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
  color: #333;
  text-align: center;
}

/* Estilo do cabeçalho h1 */
h1 {
  font-size: 24px;
  margin: 20px 0;
  color: #333;
}

/* Estilo do contêiner principal */
.titulo {
  margin: 0 20px 0;
  padding: 10px;
  font-size: 24px;
  font-family: Arial, Helvetica, sans-serif;
  
}

.container {
  display: inline-block;
  vertical-align: top;
  margin: 0 20px 20px;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
/* Estilo container Power/On ambientes */
.container2 {
  display: inline-block;
  vertical-align: top;
  margin: 0 10px 10px;
  border-radius: 10px;
}

.setupdemo {
  display: inline-block;
  vertical-align: top;
  margin: 0 20px 20px;
  background-color: #dcdada;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
    align-items: center;
    justify-content: center;

}

/* Estilo do banner pequeno */
.small-banner {
  background-color: #696b6c;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
}

/* Estilo do botão */
.button {
  width: 200px;
  height: 40px;
  background-color: #1f6b0a;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #63f14e;
}

/* Estilo da mensagem de atualização */
.message {
  margin: 10px 0;
  color: #555;
  font-style: italic;
}

/* Estilo para a seção Power/On dos ambientes */
h1 {
  font-size: 24px;
  margin: 20px 0;
  color: #333;
}

/* Estilo para os formulários de configuração */
form {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}



/* Estilo para as caixas de seleção nos formulários */
select {
  width: 200px;
  height: 30px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 5px;
  font-size: 14px;
}

/* Estilo para os botões nos formulários */
button[type="button"] {
  width: 150px;
  height: 40px;
  background-color: #1f6b0a;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button[type="button"]:hover {
  background-color: #46df46;
}

/* Estilo para as mensagens de resultado */
p span {
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
}

/* Estilo para as mensagens de erro */
.error-message {
  color: #e74c3c;
  font-size: 16px;
  margin-top: 10px;
}

#navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color:  rgb(142, 229, 99);
}

#navbar h2 a {
  display: flex;
  align-items: center;
  gap: .5rem;

}

#navbar input {
  padding: .2rem .8rem;
  border-radius: 4px;
  border: none;

}

#navbar button {
  background-color: #1f6b0a;
  border: 2px solid #25860a;
  border-radius: 4px;
  color: #f5f1f1;
  padding: 0.3rem;
  font-size: 1.3rem;
  align-items: center;
  cursor: pointer;
  transition: .4s;

}

#navbar button:hover {
  background-color: #ee1919;
}

